body,
html {
  font-family: 'Cholontika', 'myriadB', sans-serif;
}

@font-face {
  font-family: 'Cholontika';  
  src: local('Cholontika'), url(./fonts/Cholontika-Regular.ttf) format('truetype');
}

.font-Cholontika{
  font-family: 'Cholontika';
}


@font-face {
  font-family: 'myriadB';  
  src: local('myriadB'), url(./fonts/myriad-bengali-regular.ttf) format('truetype');
}

.font-myriadB {
  font-family: 'myriadB';
} 


@font-face {
  font-family: 'Rajdip';  
  src: local('Rajdip'), url(./fonts/Rajdip-Regular.ttf) format('truetype');
}

.font-Rajdip {
  font-family: 'Rajdip';
} 

@font-face {
  font-family: 'Noirrit';  
  src: local('Noirrit'), url(./fonts/Noirrit-Regular.ttf) format('truetype');
}

.font-Noirrit {
  font-family: 'Noirrit';
} 

